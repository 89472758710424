<template>
  <div class="bv-attendance-module-container" v-if="clinic.type === 'BV'">
    <div class="d-flex">
      <b-form-checkbox
        :checked="Boolean(form.forwarding_ticket)"
        :disabled="!canEdit"
        @change="checked => form.forwarding_ticket = !form.forwarding_ticket"
      >
        Encaminhar paciente para atendimento especializado presencial
      </b-form-checkbox>
    </div>

    <b-input
      class="mt-2"
      type="text"
      :disabled="!form.forwarding_ticket || !canEdit"
      placeholder="Anotação para encaminhamento"
      v-model="form.forwarding_ticket_message"
      @blur="createOrUpdate"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props:{
    attendanceId: String
  },
  created(){
    if(this.clinic.type === 'BV') this.getBvAttendance()
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      form: this.getDefaultForm(),
      bvAttendance: null
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit
    })
  },
  methods: {
    getDefaultForm(){
      return {
        attendance_id: this.bvAttendance?.attendance_id ?? this.attendanceId,
        forwarding_ticket: this.bvAttendance?.forwarding_ticket ?? false,
        forwarding_ticket_message: this.bvAttendance?.forwarding_ticket_message ?? null
      }
    },

    getBvAttendance(){
      const loading = this.$loading.show();
      this.bvAttendance = null
      this.api.getBvAttendanceByAttendanceId(this.attendanceId)
      .then((res) => {
        if(res.data){
          this.bvAttendance = res.data?.id ? res.data : null
          this.form = this.getDefaultForm()
        }
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => loading.hide());
    },
    createOrUpdate(){
      if(this.bvAttendance) this.updateBvAttendance()
      else this.createBvAttendance()
    },
    createBvAttendance() {
      const loading = this.$loading.show();
      this.api.createBvAttendance(this.form)
      .then(() => {
        this.getBvAttendance()
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => loading.hide());
    },
    updateBvAttendance() {
      const loading = this.$loading.show();
      this.api.updateBvAttendance(this.bvAttendance?.id, this.form)
      .then(() => {
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => loading.hide());
    },
    deleteBvAttendance() {
      const loading = this.$loading.show();
      this.api.deleteBvAttendance(this.bvAttendance.id)
      .then(() => {
        this.getBvAttendance()
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => loading.hide());
    },
  },
  watch: {
    'form.forwarding_ticket': function(newVal, oldValue) {
      if(!newVal && this.bvAttendance?.id ) this.deleteBvAttendance()
    },
  },
}
</script>
<style lang="scss" scoped>
.bv-attendance-module-container {
  margin-top: 38px;
  padding: 16px;
}
</style>
